<template>
  <transition name="slide-fade" class="">
    <div class="bb-filter-item" id="categories_filter">
      <div class="bb-filter-item__label">Categorías</div>
      <div class="bb-filter" v-click-outside="config">
        <div
          class="bb-filter__select"
          @click="toggle"
          :class="toggleFilter ? 'bb-filter__select--active' : ''"
        >
          {{ label }}
          <img
            src="../../../assets/img/ico/ico-chevron-up.svg"
            loading="lazy"
            alt=""
            width="16px"
            :class="
              toggleFilter
                ? 'bb-filter__chevron bb-filter__chevron--active'
                : 'bb-filter__chevron '
            "
          />
        </div>
        <div class="bb-filter__items" v-show="toggleFilter">
          <div
            class="bb-filter__item bb-filter__item--option"
            v-for="(category, index) in categoriesWithAll()"
            :key="index"
            @click="applyFilter(category)"
            :class="category.id === selected ? 'bb-filter__item--active' : ''"
          >
            <span v-text='$t("categories." + category.translationKey)' />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import IsFilter from "./Mixins/IsFilter";
import { mapGetters } from "vuex";

export default {
  name: "CategoryFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside, IsFilter],
  data() {
    return {
      selected: 99,
    };
  },
  computed: {
    ...mapGetters({
      guestProductFilters: "guestProductFilters",
      categories: "categories",
      myList: "myList",
    }),
    label() {
      return this.selected === 99
        ? this.$t("filters.allf")
        : this.$t("categories." + this.categories.find((category) => category.id === this.selected)?.translationKey);
    },
    onlyCategoriesFromProducts() {
      const foundCategories = (this.myList.products || [])
        .map((product) => product.mainCategoryId)
        .reduce((output, categoryId) => ({
          ...output,

          [categoryId]: true,
        }), {});

      return this.categories.filter((category) => foundCategories[category.id]);
    },
  },
  methods: {
    categoriesWithAll() {
      const categoriesWithAll = [
        { id: 99, translationKey: "all" },

        ...this.onlyCategoriesFromProducts,
      ];

      return categoriesWithAll;
    },
    applyFilter(category) {
      this.selected = category.id;
      let newFilters = [...this.guestProductFilters];
      const found = newFilters.find((filter) => filter.name === "category");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      if (this.selected !== 99) {
        newFilters.push({
          name: "category",
          values: {
            id: category.id,
            slug: category.slug,
            name: category.name,
          },
        });
      }
      this.$setGuestProductFilters(newFilters);
      this.close();
    },
  },
  async mounted() {
    await this.$store.dispatch("getCategories");
    const categoryFound = this.guestProductFilters.find(
      (filter) => filter.name === "category"
    );
    if (categoryFound) {
      this.selected = categoryFound.values.id;
    }
  },
};
</script>
