<template>
  <input
    type="search"
    class="bb-form bb-form--search bb-form--sm"
    :placeholder="$t('filters.searchInTheList')"
    @input="debounceSearch"
  />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchFilter",
  data() {
    return {
      textToSearch: "",
      debounce: null,
    };
  },
  computed: {
    ...mapGetters({
      guestProductFilters: "guestProductFilters",
    }),
  },
  methods: {
    debounceSearch(event) {
      this.textToSearch = "";
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.textToSearch = event.target.value;
        this.applyFilter();
      }, 600);
    },
    applyFilter() {
      const newFilters = [...this.guestProductFilters].filter((filter) => (
        filter.name !== "search"
      ));

      if (this.textToSearch !== "") {
        newFilters.push({
          name: "search",
          values: {
            textToSearch: this.textToSearch,
          },
        });
      }

      this.$setGuestProductFilters(newFilters, false);
    },
  },
};
</script>
